import axios from "axios";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useCallback, useEffect, useState } from "react";
import { AiFillSignal } from "react-icons/ai";
import { BsFiles } from "react-icons/bs";
import { FaCodeBranch, FaRegUser } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { Link } from "react-router-dom";
import { NavigationItemHrd } from "./NavigationItemHrd";

const LayoutHrdLogic = () => {
  const [dataMenus, setDataMenus] = useState([]);

  const { REACT_APP_HOST_API } = process.env;

  const handleSelectMenu = (param) => {
    encryptCookies("defaultHrdMenu", param);
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(REACT_APP_HOST_API, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      const selectedMenu = data.data[0].role.permissions.modules.find(
        (x) => x.id === "mdlHRD"
      ).menus;
      setDataMenus(selectedMenu);
    } catch (error) {}
  }, [REACT_APP_HOST_API]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Menu variable
  const mnuDivision = dataMenus.find((x) => x.id === "mnuDivision");
  const mnuEmployee = dataMenus.find((x) => x.id === "mnuEmployee");
  const mnuActivity = dataMenus.find((x) => x.id === "mnuActivity");

  NavigationItemHrd.push({
    key: "dashboard",
    icon: <MdDashboardCustomize />,
    label: <Link to="/dashboard">Dashboard</Link>,
  });

  if (mnuDivision) {
    NavigationItemHrd.push({
      key: "mnuDivision",
      icon: <FaCodeBranch />,
      label: (
        <Link
          to="/division"
          onClick={() => handleSelectMenu("mnuDivision")}
          style={
            mnuDivision.type === "disabled"
              ? { pointerEvents: "none", color: "#777" }
              : {}
          }
        >
          Division
        </Link>
      ),
    });
  }

  if (mnuEmployee) {
    NavigationItemHrd.push({
      key: "mnuEmployee",
      icon: <FaRegUser />,
      label: (
        <Link
          to="/employee"
          onClick={() => handleSelectMenu("mnuEmployee")}
          style={
            mnuEmployee.type === "disabled"
              ? { pointerEvents: "none", color: "#777" }
              : {}
          }
        >
          Employee
        </Link>
      ),
    });
  }

  if (mnuActivity) {
    NavigationItemHrd.push({
      key: "activity",
      icon: <AiFillSignal />,
      children: [
        {
          key: "mnuActivity",
          icon: <AiFillSignal />,
          label: (
            <Link
              to="/activity"
              onClick={() => handleSelectMenu("mnuActivity")}
              style={
                mnuActivity.type === "disabled"
                  ? { pointerEvents: "none", color: "#777" }
                  : {}
              }
            >
              Activity
            </Link>
          ),
        },
        {
          key: "mnuRecapPresence",
          icon: <BsFiles />,
          label: (
            <Link
              to="/activity/presence-:id/recap-presence-:id"
              onClick={() => handleSelectMenu("mnuRecapPresence")}
              style={
                mnuActivity.type === "disabled"
                  ? { pointerEvents: "none", color: "#777" }
                  : {}
              }
            >
              Recap Presence
            </Link>
          ),
        },
      ],
      label: "Activity",
    });
  }

  if (dataMenus.length > 0) {
    NavigationItemHrd.push({
      key: "logout",
      icon: <TbLogout />,
      label: <Logout />,
    });
  }

  const uniqueIds = [];

  const newNavigationItemHrds = NavigationItemHrd.filter((item) => {
    const isDuplicate = uniqueIds.includes(item.key);

    if (!isDuplicate) {
      uniqueIds.push(item.key);
      return true; // same as => return uniqueIds
    }

    return false;
  });

  return { newNavigationItemHrds };
};

export default LayoutHrdLogic;
