import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import MasterUserLogic from "./MasterUserLogic";
import ResetPasswordUsers from "./ResetPasswordUser";

const MasterUser = () => {
  const {
    dataId,
    dataKey,
    keyword,
    columns,
    dataTable,
    isLoading,
    showAddUser,
    showEditUser,
    showResetPassword,
    onReset,
    onCreate,
    onCancel,
    onUpdate,
    setDataTable,
    handleChange,
    setShowAddUser,
  } = MasterUserLogic();

  return (
    <div>
      <div className="table-header">
        <h1>Users</h1>
        <Button type="primary" onClick={() => setShowAddUser(true)}>
          Add Users
        </Button>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleChange}
          placeholder="Cari User"
        />
      </div>
      <Table
        size="small"
        dataSource={dataKey}
        columns={columns}
        loading={isLoading}
        pagination={{
          current: dataTable.current_page,
          pageSize: dataTable.per_page,
          total: dataTable.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: (curr, size) => {
            setDataTable((prev) => {
              return {
                ...prev,
                current_page: curr,
                per_page: size,
              };
            });
          },
        }}
      />
      <AddUser show={showAddUser} onCreate={onCreate} onCancel={onCancel} />
      <EditUser
        id={dataId}
        show={showEditUser}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <ResetPasswordUsers
        id={dataId}
        show={showResetPassword}
        onReset={onReset}
        onCancel={onCancel}
      />
    </div>
  );
};

export default MasterUser;
