import { Layout, Menu } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import BreadCrumb from "components/UI/BreadCrumb";
import Cookies from "js-cookie";
import LayoutSystemLogic from "./LayoutSystemLogic";

const { Header, Sider, Content } = Layout;

const LayoutSystem = (props) => {
  const { collapsed, newNavigationItemSystem, setCollapsed } =
    LayoutSystemLogic();

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />
        <Menu
          style={{
            fontSize: "1.1em",
          }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={
            Cookies.get("defaultSystemMenu")
              ? decryptCookies("defaultSystemMenu")
              : ""
          }
          items={newNavigationItemSystem}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            background: "#eee",
          }}
        />
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "95vh",
            borderRadius: 5,
            position: "relative",
            top: "-70px",
            boxShadow: "0 0 5px rgba(0,0,0,.1)",
          }}
        >
          <BreadCrumb />
          {props.content}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutSystem;
