import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  if (Cookies.get("token") === undefined) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RequireAuth;
