import { createContext, useState } from "react";

export const PermissionContext = createContext();

export const PermissionProvider = (props) => {
  const [showMenuTable, setShowMenuTable] = useState(false);
  const [showFunctionTable, setShowFunctionTable] = useState(false);

  return (
    <PermissionContext.Provider
      value={{
        showMenuTable,
        showFunctionTable,
        setShowMenuTable,
        setShowFunctionTable,
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  );
};
