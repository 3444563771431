import { decryptCookies } from "components/Helper/CookiesHelper";
import Backdrop from "components/UI/Backdrop";
import Cookies from "js-cookie";
import { NavigationItemHrd } from "Layouts/LayoutHrd/NavigationItemHrd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { NavigationItemSystem } from "Layouts/LayoutSystem/NavigationItemSystem";

const Logout = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  const handleLogout = () => {
    setLoading(true);
    fetch(REACT_APP_HOST_API + "/auth/logout", {
      headers: { Authorization: "Bearer " + decryptCookies("token") },
      method: "post",
    })
      .then(() => {
        Cookies.remove("acl");
        Cookies.remove("data");
        Cookies.remove("time");
        Cookies.remove("token");
        Cookies.remove("login");
        Cookies.remove("activityId");
        Cookies.remove("defaultHrdMenu");
        Cookies.remove("defaultSystemMenu");

        navigate("/");
        NavigationItemHrd.splice(0, NavigationItemHrd.length);
        NavigationItemSystem.splice(0, NavigationItemSystem.length);
      })
      .catch((err) => alert(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}
      <Link to="#" onClick={handleLogout}>
        Logout
      </Link>
    </>
  );
};

export default Logout;
