import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = () => {
  const location = useLocation();

  const breadcrumbView = () => {
    const { pathname } = location;

    const pathnames = pathname.split("/").filter((item) => item);

    return (
      <Breadcrumb>
        {pathnames.length > 0 ? (
          <Breadcrumb.Item>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Item>
        ) : null}

        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

          return (
            <Breadcrumb.Item key={index}>
              <Link to={routeTo}>{name}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  return <>{breadcrumbView()}</>;
};

export default BreadCrumb;
