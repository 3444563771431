import { Form, Input, message, Modal, Radio, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

const AddUser = ({ show, onCreate, onCancel }) => {
  const [dataRole, setDataRole] = useState([]);
  const [radioValue, setRadioValue] = useState(true);
  const [keywordRole, setKeywordRole] = useState("");
  const [dataEmployee, setDataEmployee] = useState([]);
  const [keywordEmployee, setKeywordEmployee] = useState("");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/system/roles?page=1&limit=10&keyword=${keywordRole}&mode=list`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        setDataRole(data.data);
      } catch (error) {
        alert(`Get data ERROR
${error.message}`);
      }
    };

    fetchRoles();

    const fetchEmployee = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/employee-list?keyword=`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        setDataEmployee(data.data);
      } catch (error) {
        alert(`Gagal mengambil data employee
${error.message}`);
      }
    };

    fetchEmployee();
  }, [REACT_APP_HOST_API, keywordEmployee, keywordRole]);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        axios
          .post(
            REACT_APP_HOST_API + `/system/users`,
            {
              name: fieldsValue.name,
              role: fieldsValue.role, // walaupun dari be keluar role_name tapi value aslinya yg diiput adalah role
              email: fieldsValue.email,
              employee_id: fieldsValue.employee_id,
              password: fieldsValue.password,
              password_confirmation: fieldsValue.password_confirmation,
              verified: radioValue,
            },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          )
          .then(() => {
            message.success("Berhasil input data");
            form.resetFields();
            onCreate();
          })
          .catch((err) => alert(err.message));
      })
      .catch(() => alert("Fields error"));
  };

  return (
    <Modal visible={show} onOk={onFinish} onCancel={onCancel} okText="Submit">
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 5, message: "Masukkan minimal 5 karakter" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(val) => setKeywordRole(val)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataRole !== null &&
              dataRole.map((role) => (
                <Select.Option key={role.name} value={role.name}>
                  {role.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Harap diisi" },
            {
              type: "email",
              message: "Please input a valid email",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item label="ID Karyawan" name="employee_id">
          <Select
            showSearch
            onChange={(val) => setKeywordEmployee(val)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataEmployee !== null &&
              dataEmployee.map((employee) => (
                <Select.Option key={employee.name} value={employee.id}>
                  {`${employee.name} - ${employee.nip}`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Password Confirmation"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        {/* <Form.Item
          label="Verified"
          name="verified"
          rules={[{ required: true, message: "Harap diisi" }]}
        > */}
        <Radio.Group
          onChange={handleRadioChange}
          value={radioValue}
          defaultValue={true}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {/* </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddUser;
