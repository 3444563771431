import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

const ResetPasswordUsers = ({ id, show, onReset, onCancel }) => {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const onFinish = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        axios
          .put(
            REACT_APP_HOST_API + `/system/users/${id}`,
            {
              password: fieldsValue.password,
              password_confirmation: fieldsValue.password_confirmation,
            },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          )
          .then(() => {
            message.success("Berhasil input data");
            form.resetFields();
            onReset();
          })
          .catch((err) => alert(err.message));
      })
      .catch(() => alert("Fields error"));
  };

  return (
    <Modal visible={show} onOk={onFinish} onCancel={onCancel} okText="Submit">
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Password Confirmation"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordUsers;
