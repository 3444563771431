import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./PermissionError.css";

const PermissionError = () => {
  let navigate = useNavigate();

  return (
    <div className="container">
      <p className="text">
        SORRY...
        <br />
        YOU DON'T HAVE PERMISSION FOR THAT PAGE
      </p>
      <Button type="primary" size="large" onClick={() => navigate(-2)}>
        Back to previous page
      </Button>
    </div>
  );
};

export default PermissionError;
