import { Form, Input, message, Modal, Radio, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";

const EditUser = ({ id, show, onUpdate, onCancel }) => {
  const [dataRole, setDataRole] = useState([]);
  const [radioValue, setRadioValue] = useState("");
  const [keywordRole, setKeywordRole] = useState("");
  const [dataEmployee, setDataEmployee] = useState([]);
  const [keywordEmployee, setKeywordEmployee] = useState("");
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/system/roles?page=1&limit=10&keyword=${keywordRole}&mode=list`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        setDataRole(data.data);
      } catch (error) {
        alert(`Get data ERROR
${error.message}`);
      }
    };

    fetchRoles();

    const fetchEmployee = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/employee-list?keyword=${keywordEmployee}`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        setDataEmployee(data.data);
      } catch (error) {
        alert(`Gagal mengambil data employee
${error.message}`);
      }
    };

    fetchEmployee();
  }, [REACT_APP_HOST_API, keywordEmployee, keywordRole]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/system/users/${id}`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        form.setFieldsValue({
          name: data.data.name,
          role: data.data.role.name,
          email: data.data.email,
          employee_id: data.data.employee_id,
          verified: data.data.verified,
        });
      } catch (error) {
        alert(error.message);
      }
    };

    if (show) {
      getUser();
    }
  }, [REACT_APP_HOST_API, id, form, show]);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        axios
          .put(
            REACT_APP_HOST_API + `/system/users/${id}`,
            {
              name: fieldsValue.name,
              role: fieldsValue.role,
              email: fieldsValue.email,
              employee_id: fieldsValue.employee_id,
              verified: fieldsValue.verified,
            },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          )
          .then((res) => {
            message.success(res.data.message, 2);
            form.resetFields();
            onUpdate();
          })
          .catch((err) => alert(err.message));
      })
      .catch(() => alert("Field Error"));
  };

  return (
    <Modal visible={show} onOk={onFinish} onCancel={onCancel} okText="Submit">
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Nama"
          name="name"
          rules={[{ min: 5, message: "Masukkan minimal 5 karakter" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role">
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(value) => setKeywordRole(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataRole !== null &&
              dataRole.map((role) => (
                <Select.Option key={role.name} value={role.name}>
                  {role.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please input a valid email",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item label="ID Karyawan" name="employee_id">
          <Select
            showSearch
            onChange={(val) => setKeywordEmployee(val)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataEmployee !== null &&
              dataEmployee.map((employee) => (
                <Select.Option key={employee.name} value={employee.id}>
                  {`${employee.name} - ${employee.nip}`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Verified"
          name="verified"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Radio.Group onChange={handleRadioChange} value={radioValue}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUser;
