import { Button, Form, Input } from "antd";
import axios from "axios";
import { encryptCookies } from "components/Helper/CookiesHelper";
import Backdrop from "components/UI/Backdrop";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  const onFinish = (value) => {
    setIsLoading(true);

    axios
      .post(
        REACT_APP_HOST_API + "/auth/login",
        {
          email: value.email,
          password: value.password,
        },
        { timeout: 5000 }
      )
      .then((res) => {
        const { token } = res.data.token;
        const { role } = res.data.data[0];

        encryptCookies("token", token);
        encryptCookies("data", JSON.stringify(role));
        encryptCookies("login", "true");

        setIsLoading(false);
        navigate("/dashboard", { replace: true });
      })
      .catch((res) => {
        if (res.message.includes("timeout")) {
          setIsLoading(false);
          alert("Waktu permintaan habis, silahkan coba lagi");
        } else {
          setIsLoading(false);
          const error = new Error(res.message);
          alert(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login">
      <div className="login__wrapper">
        <div className="left__content">
          <div className="content__wrapper">
            <h3 className="content__wrapper-title">Sistem HRD SMA FG</h3>
            <p className="content__wrapper-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est aut
              sapiente expedita molestiae. Voluptas dolores error quaerat porro
              harum architecto? Assumenda consequuntur numquam fugit, nihil
              neque quis dolor debitis officiis voluptates exercitationem minus,
              ratione sapiente!
            </p>
          </div>
        </div>
        <div className="right__content">
          <div className="content__wrapper">
            <h3 className="content__wrapper-title">Login</h3>
            <div className="content__wrapper-form">
              <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    { type: "email", message: "please enter a valid email" },
                  ]}
                  className="border-only-bottom"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "please enter password" }]}
                  className="border-only-bottom"
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" shape="round" block htmlType="submit">
                    Login
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    block
                    onClick={() => navigate("/scan-presence")}
                  >
                    Scan Presensi
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {isLoading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}
    </div>
  );
};

export default Login;
