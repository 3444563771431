import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Page404.module.css";

const Page404 = () => {
  let navigate = useNavigate();

  return (
    <div className={styles.container}>
      <p className={styles.text}>PAGE NOT FOUND</p>
      <Button type="primary" size="large" onClick={() => navigate(-1)}>
        Back to previous page
      </Button>
    </div>
  );
};

export default Page404;
