import { Button, message, Popconfirm } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterUserLogic = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataUsers, setDataUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    setIsLoading(true);

    const fetchUser = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/system/users?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );

        setDataUsers(data.data.data);
        setDataTable((prev) => {
          return {
            ...prev,
            current_page: data.data.meta.current_page,
            per_page: data.data.meta.per_page,
            total: data.data.meta.total,
          };
        });
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();

    return () => {
      cancelToken.cancel();
      setFetchStatus(false);
    };
  }, [
    dataTable.current_page,
    dataTable.per_page,
    fetchStatus,
    keyword,
    REACT_APP_HOST_API,
  ]);

  const columns = [
    { title: "No", dataIndex: "index", align: "center" },
    { title: "Nama", dataIndex: "name", align: "center" },
    { title: "Role", dataIndex: "role_name", align: "center" },
    { title: "Email", dataIndex: "email", align: "center" },
    { title: "Nama karyawan", dataIndex: "nameEmployee", align: "center" },
    { title: "Nip Karyawan", dataIndex: "nipEmployee", align: "center" },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={() => {
                setDataId(id);
                setShowEditUser(true);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={() => {
                setDataId(id);
                setShowResetPassword(true);
              }}
              style={{
                marginRight: 5,
                backgroundColor: "orange",
                color: "#fff",
                border: "none",
              }}
            >
              Reset Password
            </Button>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              onConfirm={onDelete}
              okText="delete"
              cancelText="cancel"
            >
              <Button
                type="danger"
                size="small"
                shape="round"
                onClick={() => {
                  setDataId(id);
                }}
              >
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const dataKey = dataUsers.slice(0, dataTable.per_page).map((data, index) => {
    return {
      ...data,
      key: data.id,
      index: index + 1,
      nameEmployee: data.employee ? data.employee.name : "",
      nipEmployee: data.employee ? data.employee.nip : "",
    };
  });

  const onCancel = () => {
    setShowAddUser(false);
    setShowEditUser(false);
    setShowResetPassword(false);
  };

  const onCreate = useCallback(() => {
    setShowAddUser(false);
    setFetchStatus(true);
  }, []);

  const onUpdate = useCallback(() => {
    setShowEditUser(false);
    setFetchStatus(true);
  }, []);

  const onReset = () => {
    setShowResetPassword(false);
    setFetchStatus(true);
  };

  const onDelete = () => {
    axios
      .delete(REACT_APP_HOST_API + "/system/users/" + dataId, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      })
      .then((res) => {
        message.success(res.data.message, 2);
        setFetchStatus(true);
      })
      .catch((error) => alert(error.message));
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  return {
    dataId,
    dataKey,
    keyword,
    columns,
    dataTable,
    isLoading,
    showAddUser,
    showEditUser,
    showResetPassword,
    onReset,
    onCreate,
    onCancel,
    onUpdate,
    setDataTable,
    handleChange,
    setShowAddUser,
  };
};

export default MasterUserLogic;
