import axios from "axios";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { FaCodeBranch, FaUserLock } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { MdDashboardCustomize } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { Link } from "react-router-dom";
import { NavigationItemSystem } from "./NavigationItemSystem";

const LayoutSystemLogic = () => {
  const [dataMenus, setDataMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchPermission = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          },
          { cancelToken: cancelToken.token }
        );

        const selectedMenu = data.data[0].role.permissions.modules.find(
          (x) => x.id === "mdlSystem"
        ).menus;
        setDataMenus(selectedMenu);
      } catch (error) {
        alert(error);
      }
    };

    fetchPermission();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API]);

  const handleSelectMenu = (param) => {
    encryptCookies("defaultSystemMenu", param);
  };

  const menuRole = dataMenus.find((x) => x.id === "mnuRole");
  const menuUsers = dataMenus.find((x) => x.id === "mnuUsers");
  const menuModule = dataMenus.find((x) => x.id === "mnuModule");

  NavigationItemSystem.push({
    key: "dashboard",
    icon: <MdDashboardCustomize />,
    label: <Link to="/dashboard">Dashboard</Link>,
  });

  if (menuModule) {
    NavigationItemSystem.push({
      key: "mnuModule",
      icon: <FaCodeBranch />,
      label: (
        <Link
          to="/system-modules"
          onClick={() => handleSelectMenu("mnuModule")}
          style={
            menuModule.type === "disabled"
              ? { pointerEvents: "none", color: "#777" }
              : {}
          }
        >
          Module
        </Link>
      ),
    });
  }

  if (menuRole) {
    NavigationItemSystem.push({
      key: "mnuRole",
      icon: <FaUserLock />,
      label: (
        <Link
          to="/system-roles"
          onClick={() => handleSelectMenu("mnuRole")}
          style={
            menuRole.type === "disabled"
              ? { pointerEvents: "none", color: "#777" }
              : {}
          }
        >
          Role
        </Link>
      ),
    });
  }

  if (menuUsers) {
    NavigationItemSystem.push({
      key: "mnuUsers",
      icon: <HiUsers />,
      label: (
        <Link
          to="/system-users"
          onClick={() => handleSelectMenu("mnuUsers")}
          style={
            menuUsers.type === "disabled"
              ? { pointerEvents: "none", color: "#777" }
              : {}
          }
        >
          Users
        </Link>
      ),
    });
  }

  if (dataMenus.length > 0) {
    NavigationItemSystem.push({
      key: "logout",
      icon: <TbLogout />,
      label: <Logout />,
    });
  }

  const uniqueIds = [];

  const newNavigationItemSystem = NavigationItemSystem.filter((item) => {
    const isDuplicate = uniqueIds.includes(item.key);

    if (!isDuplicate) {
      uniqueIds.push(item.key);
      return true; // same as => return uniqueIds
    }

    return false;
  });

  return {
    collapsed,
    newNavigationItemSystem,
    setCollapsed,
  };
};

export default LayoutSystemLogic;
