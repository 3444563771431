import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "components/Auth/Login";
import RequireAuth from "components/Auth/RequireAuth";
import RequireLogin from "components/Auth/RequireLogin";
import RequireMenuHrd from "components/Auth/RequireMenuHrd";
import RequireMenuSystem from "components/Auth/RequireMenuSystem";
import Backdrop from "components/UI/Backdrop";
import LayoutHrd from "Layouts/LayoutHrd/LayoutHrd";
import LayoutScan from "Layouts/LayoutScan/LayoutScan";
import LayoutSystem from "Layouts/LayoutSystem/LayoutSystem";
import Page404 from "pages/error-pages/page-404/Page404";
import PermissionError from "pages/error-pages/permission-error/PermissionError";
import { PermissionProvider } from "pages/si-system/master-role/permissions/PermissionContext";
import MasterUser from "pages/si-system/master-user/MasterUser";

// lazy route dashboard
const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));

// lazy route hrd
const Activity = lazy(() => import("pages/si-hrd/activity/Activity"));
const Division = lazy(() => import("pages/si-hrd/division/Division"));
const Employee = lazy(() => import("pages/si-hrd/employee/Employee"));
const EmployeeDetail = lazy(() =>
  import("pages/si-hrd/employee/EmployeeDetail")
);
const Presence = lazy(() => import("pages/si-hrd/presence/Presence"));
const RecapPresence = lazy(() =>
  import("pages/si-hrd/recap-presence/RecapPresence")
);
const ScanPresence = lazy(() =>
  import("pages/si-scanpresence/scan/ScanPresence")
);

// lazy route system
const MasterModule = lazy(() =>
  import("pages/si-system/master-module/module/MasterModule")
);
const MenusModule = lazy(() =>
  import("pages/si-system/master-module/menus/MenusModule")
);
const MasterFunction = lazy(() =>
  import("pages/si-system/master-module/functions/MasterFunction")
);
const MasterRole = lazy(() =>
  import("pages/si-system/master-role/role/MasterRole")
);
const Permissions = lazy(() =>
  import("pages/si-system/master-role/permissions/Permissions")
);

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* home/login */}
        <Route
          path="/"
          element={
            <RequireLogin>
              <Login />
            </RequireLogin>
          }
        />

        {/* dashboard */}
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                <Dashboard />
              </Suspense>
            </RequireAuth>
          }
        />

        {/* hrd */}
        <Route
          path="division"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="mnuDivision">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <Division />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="employee"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="mnuEmployee">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <Employee />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="employee/employee-detail-:id"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="btnDetailEmployee">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <EmployeeDetail />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="activity"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="mnuActivity">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <Activity />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="activity/presence-:id"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="mnuPresence">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <Presence />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="activity/presence-:id/recap-presence-:id"
          element={
            <RequireAuth>
              <RequireMenuHrd menu="mnuActivity">
                <LayoutHrd
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <RecapPresence />
                    </Suspense>
                  }
                />
              </RequireMenuHrd>
            </RequireAuth>
          }
        />
        <Route
          path="scan-presence"
          element={
            <LayoutScan>
              <Suspense>
                <ScanPresence />
              </Suspense>
            </LayoutScan>
          }
        />

        {/* system */}
        <Route
          path="system-modules"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuModule">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <MasterModule />
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />
        <Route
          path="system-modules/menus-:module_id"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuModule">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <MenusModule />
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />
        <Route
          path="system-modules/menus-:module_id/functions-:menu_id"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuModule">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <MasterFunction />
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />
        <Route
          path="system-roles"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuModule">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <PermissionProvider>
                        <MasterRole />
                      </PermissionProvider>
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />
        <Route
          path="system-roles/permissions-:role_name"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuModule">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <PermissionProvider>
                        <Permissions />
                      </PermissionProvider>
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />

        <Route
          path="system-users"
          element={
            <RequireAuth>
              <RequireMenuSystem menu="mnuUsers">
                <LayoutSystem
                  content={
                    <Suspense fallback={<Backdrop>Loading...</Backdrop>}>
                      <PermissionProvider>
                        <MasterUser />
                      </PermissionProvider>
                    </Suspense>
                  }
                />
              </RequireMenuSystem>
            </RequireAuth>
          }
        />

        {/* if dont have access */}
        <Route path="permission-error" element={<PermissionError />} />

        {/* if no route */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
