import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import Backdrop from "components/UI/Backdrop";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Navigate } from "react-router-dom";

const RequireMenuHrd = ({ menu, children }) => {
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeFunction, setEmployeeFunction] = useState([]);

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    setLoading(true);
    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(REACT_APP_HOST_API, {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        });

        setMenus(
          data.data[0].role.permissions.modules.find((x) => x.id === "mdlHRD")
            .menus
        );
        setEmployeeFunction(
          data.data[0].role.permissions.modules
            .find((x) => x.id === "mdlHRD")
            .menus.find((x) => x.id === "mnuEmployee").functions
        );
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    };

    fetchDashboard();
  }, [REACT_APP_HOST_API]);

  if (menu === "btnDetailEmployee") {
    const btnDetailEmployee = employeeFunction.find((x) => x.id === menu);

    if (employeeFunction.length > 0) {
      if (!btnDetailEmployee || btnDetailEmployee.type === "disabled") {
        return <Navigate to="/permission-error" />;
      }
    }
  } else {
    const targettedMenu = menus.find((x) => x.id === menu);

    if (menus.length > 0) {
      if (!targettedMenu || targettedMenu.type === "disabled") {
        return <Navigate to="/permission-error" />;
      }
    }
  }

  return (
    <>
      {loading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}
      {children}
    </>
  );
};

export default RequireMenuHrd;
